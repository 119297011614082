import IndiaOffice from "../../Assets/images/android_2.png";
import USAOffice from "../../Assets/images/OfficeImage2.png";

const OfficeLocation = [
    {
        id : 1,
        countryName : "india",
        address : "AB/14, Nandanvan Industrial Estate, Teen Hath Naka, Thane (W), Maharashtra, India - 400604",
        phoneNumber : "+91 9819783891",
        email : "akshay@techsierra.in",
        officeImage : IndiaOffice
    },
    {
        id : 2,
        countryName : "usa",
        address : "6909 Timber Creek Ct, Clarksville, MD 21029",
        phoneNumber : "+1-917-829-1671",
        email:"dan.lawlor@techsierra.in",
        officeImage : USAOffice
    }
]

export {OfficeLocation}